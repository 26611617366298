<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section>
    <b-modal
      v-model="showModal"
      size="md"
      no-close-on-esc
      no-close-on-backdrop
      title="Update Revenue Percentage"
      class="p-4"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="submitData"
        >
          <div
            v-if="dataLoad"
            class="spinner"
          >
            <b-spinner
              small
              class="d-inline-block"
            />
            <span class="sr-only">Loading...</span>
          </div>
          Update
        </b-button>
      </template>
      <validation-observer ref="updateRevenuePercentageForm">
        <ValidationProvider
          v-slot="{ errors }"
          name="Revenue Percentage"
          vid="cpm"
          rules="required|min_value:0|max_value:100"
        >
          <b-form-group
            label-for="cpm"
            label="Revenue Percentage"
            :class="errors.length > 0 ? 'is-invalid' : null"
            class="mx-2"
          >
            <b-form-input
              id="cpm"
              v-model="revenuePercentage"
              type="number"
              placeholder="Enter Revenue Percentage"
            />
          </b-form-group>
          <b-form-invalid-feedback
            class="ml-2"
            :state="errors.length > 0 ? false:null"
          >
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </validation-observer>
      <!-- :show="dataLoad" -->
      <b-overlay
        no-wrap
      />
    </b-modal>
  </section>
</template>
<script>
import {
  BModal, BFormGroup, BFormInput, BFormInvalidFeedback,
  BButton,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric } from '@validations'
import { min_value } from 'vee-validate/dist/rules'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BSpinner,
  },
  //   props: {
  //     data: {
  //       type: Object,
  //       required: true,
  //     },
  //   },
  emits: ['refresh-data'],
  data() {
    return {
      showModal: false,
      dataLoad: false,
      revenuePercentage: 0,
      required,
      min_value,
      numeric,
      AccountTypes,
    //   cpmValue: parseInt(this.$props.data.cpm, 10),
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    deviceData() {
      return 'this.data'
    },
  },

  methods: {
    showUpdateModal(venue) {
      this.showModal = !this.showModal
      this.revenuePercentage = Number(venue.revenue_percentage)
      this.id = venue.id
    },
    hide() {
      this.showModal = !this.showModal
    },
    async submitData() {
      this.dataLoad = true
      try {
        const success = await this.$refs.updateRevenuePercentageForm.validate()
        if (success) {
          const response = await this.$store.dispatch('venues/updateRevenuePercentage', { id: Number(this.id), revenue_percentage: Number(this.revenuePercentage) })
          if (response.statusCode === 202) {
            this.hide()
            this.$emit('refresh-data')
            showToast('Revenue Percentage', response.message.toString(), 'success')
          }
        } else {
          this.$swal('Enter valid data')
        }
      } catch (e) {
        this.$swal(e.response.data.message)
      } finally {
        this.dataLoad = false
      }
    },
  },
}
</script>
